<template>
  <div>
    <div class="flex flex-col space-y-2 border-b border-gray-800 px-6 py-4">
      <div class="flex items-center space-x-2">
        <span class="block text-sm font-semibold text-gray-200">Choose a Transaction Type to set alerts on</span>
        <span v-if="required.alertType" class="block text-xs text-red-400">Required</span>
      </div>
      <BaseSelect
        dropdown-width-class="w-full"
        button-width-class="w-full"
        :placeholder="'Select Transaction Type'"
        v-model="alertData.mode"
        :options="
          gasModes.map(g => {
            return { id: g.id, label: g.text };
          })
        "
        sort-key="text"
        id="transaction-type-dropdown"
      />
    </div>
    <div class="flex flex-col space-y-2 border-b border-gray-800 px-6 py-4">
      <div class="flex">
        <label class="text-sm font-semibold text-gray-200">Choose Speed </label>
      </div>
      <div v-for="(trig, index) in alertData.metrics" :key="index" class="space-y-2">
        <div class="flex space-x-2">
          <BaseRadio
            v-model="trig.speed"
            class="capitalize"
            :options="speed.map(x => x.id)"
            @update:model-value="trig.value = alertData.mode ? currentGasFee(alertData.mode, $event) : ''"
          />
          <span v-if="trig.required" class="text-xs text-red-400">Required</span>
        </div>
        <div class="flex items-center space-x-2">
          <BaseSelect
            v-model="trig.comparison"
            placeholder=">"
            dropdown-width-class="w-12"
            :options="
              allComparisons.map(r => {
                return { id: r.id, label: r.text };
              })
            "
            class="comparison-dropdown"
          />
          <BaseNumberInput class="w-full" v-model="trig.value">
            <template #left>$</template>
          </BaseNumberInput>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onBeforeUnmount, onMounted, ref, watch, computed } from 'vue';
import useEmitter from '@/composeables/emitter';
import { formatNumber } from '@/composeables/filters';
import useHttp from '@/composeables/http';
import { useStore } from 'vuex';
const $store = useStore();

const $http = useHttp();
const emitter = useEmitter();
const props = defineProps({
  alert: { type: Object, default: () => null }
});

const required = ref({ alertType: false });
const speed = [
  { id: 'standard', title: 'Standard' },
  { id: 'fast', title: 'Fast' },
  { id: 'instant', title: 'Instant' }
];
const gasModes = [
  { id: 'eth_transfer', text: 'ETH Transfer' },
  { id: 'erc_transfer', text: 'ERC Transfer' },
  { id: 'lp_add_remove', text: 'LP Add Remove' },
  { id: 'swap_transaction', text: 'Swap Transaction' }
];
const allComparisons = [
  { id: 'greater_than', text: '>' },
  { id: 'less_than', text: '<' }
];
const alertData = ref({
  mode: null,
  metrics: [{ speed: 'standard', value: null, comparison: 'greater_than', required: false }]
});

const gasFees = computed(() => {
  return $store.getters.gasFees;
});

function addTrigger() {
  alertData.value.metrics.push({
    speed: 'standard',
    value: null,
    comparison: null,
    required: false
  });
  alertData.value.metrics.forEach(x => {
    x.value = formatNumber(gasFees[alertData.value.mode].fees[x.speed].usd, 6);
  });
}
function removeTrigger(index) {
  alertData.value.metrics.splice(index, 1);
}
function showValidationError(data) {
  required.value.alertType = data.alertType;
  if (data.metrics) {
    data.metrics.forEach(i => {
      if (!alertData.value.metrics[i].value) alertData.value.metrics[i].required = true;
    });
  }
  setTimeout(function () {
    if (required.value) {
      required.value.alertType = false;
    }
    if (data.metrics) {
      data.metrics.forEach(i => {
        alertData.value.metrics[i].required = false;
      });
    }
  }, 5000);
}
function editData() {
  if (props.alert && props.alert.config) {
    alertData.value = JSON.parse(JSON.stringify(props.alert.config));
    alertData.value.metrics = props.alert.config.metrics.map(x => {
      x.required = false;
      return x;
    });
  }
}
function currentGasFee(mode, speed) {
  return formatNumber(gasFees.value[mode].fees[speed].usd, 6);
}

watch(
  alertData,
  () => {
    emitter.$emit('config:alert', { config: alertData.value });
  },
  { deep: true, immediate: true }
);
watch(
  () => alertData.value.mode,
  val => {
    alertData.value.metrics.forEach(x => {
      if (val) {
        x.value = currentGasFee(val, x.speed);
      } else {
        x.value = 0.0;
      }
    });
  },
  { deep: true }
);

onMounted(() => {
  editData();
  emitter.$on('GasForm', data => {
    showValidationError(data);
  });
});
onBeforeUnmount(() => {
  emitter.$off('GasForm');
});
</script>
